.root {
    flex:1 1 auto;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom:10vh;
    background-color:#00a3e0;
    color:#fff;
    text-align: center;
    height: 100%;
}
  
.title, .subtitle {
    color:#fff;
}

.subtitle {
    margin-bottom: 1rem !important;
}

.fa-brain {
    font-size:7em;
}

.root__logo {
    margin-bottom:3rem;
    padding:1rem;
}



